export default class SwipeEvents {
    constructor(handleSwipeLeft, handleSwipeRight) {
        this.handleSwipeLeft = handleSwipeLeft;
        this.handleSwipeRight = handleSwipeRight;
    }

    events = {
        onTouchStart: e => {
            this.swipestartx = e.targetTouches[0].clientX;
            this.swipestarty = e.targetTouches[0].clientY;
        },

        onTouchMove: e => {
            if (!this.swipestartx || !this.swipestarty) {
                return;
            }

            const xUp = e.touches[0].clientX;
            const yUp = e.touches[0].clientY;

            const xDiff = this.swipestartx - xUp;
            const yDiff = this.swipestarty - yUp;

            if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
                if (xDiff > 0) {
                    /* left swipe */
                    this.handleSwipeLeft();
                } else {
                    /* right swipe */
                    this.handleSwipeRight();
                }
            }
            /* reset values */
            this.swipestartx = null;
            this.swipestarty = null;
        }
    }
}
