import React from 'react';
import {observer} from "mobx-react";
import styles from "./styles/StaffCard.module.scss"
import {getBackgroundImageValue} from "../../../../stores/StyleStore";
import {getAvatarUrl} from "../../SchoolBlocks/blockUtils";

const StaffCard = observer((props) => {

    return (
        <div className={styles.container}>
            <div className={`sb-circular-profile-image ${styles.imageContainer}`}
                 role="presentation"
                 style={getBackgroundImageValue(getAvatarUrl(props.staff))}
                 title={`${props.staff.fname} ${props.staff.lname}`}>
                &nbsp;
            </div>
            <div className={styles.staffName}>
                <span>{props.staff.fname} </span>
                <span>{props.staff.lname}</span>
            </div>
            <div className={styles.staffTitle}>
                {props.staff.title}
            </div>
        </div>
    );
})

export default StaffCard;
