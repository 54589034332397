import { forwardRef, ReactNode, RefObject } from 'react';

interface CleverSliderItemProps {
    children?: ReactNode;
    key: number;
    className?: string;
    style?: { marginRight: number | undefined; };
    ref: RefObject<HTMLDivElement>;
    blockObj?: ILinksTerrace;
}

const CleverSliderItem = forwardRef<HTMLDivElement, CleverSliderItemProps>((props, ref) => {
    return (
        <div className={props.className} style={props.style} ref={ref}>
            <div className={`slideItem${props.blockObj?.type || ''}`}>
                {props.children}
            </div>
        </div>
    )
});

CleverSliderItem.displayName = 'CleverSliderItem';

export default CleverSliderItem;
